import React from "react"
import Helmet from "react-helmet"

import Layout from "../layouts/en"
import snippet from "../../static/snippet.png"
import SPHeader from "../components/static-pages-layout/static-page-header"
import Spp from "../components/static-pages-layout/static-page-p"
import SPSection from "../components/static-pages-layout/static-page-section"

const AboutSNIPPETPage = ({ location }) => {
  const langPaths = { es: "/es/acerca-de-snippet" }
  return (
    <Layout location={location} langPaths={langPaths}>
      <Helmet>
        <title>About DEVP</title>
        <meta
          name="description"
          content="Snippet is the utility token for the social and content platform of DevLand, DevLand Network"
        />
      </Helmet>
      <SPHeader
        superTitle="About"
        title="Snippet (SNIPPET)"
        imageLight={snippet}
        imageDark={snippet}
      >
        SNIPPET is the token that allows users to do actions over the social and
        content platform of DevLand, DevLand Network. Users must use their
        SNIPPET in order to post content (on a low cost, as a control for
        malicious usage of the system). They will also be able to trade their
        content using SNIPPET, creating paid articles and learning coruses.
        Also, users will be able to spend SNIPPET to get premium services and do
        a more intensive use of the platform as publishers.
      </SPHeader>
      <SPSection title="Asset info">
        <Spp>
          Asset code:{" "}
          <a
            href="https://stellar.expert/explorer/public/asset/SNIPPET-GCN2I52BQDAHGM2AYZGRJ3N4YQSDTUCHXLITKYFAFCG5DKSEVIJFK4FF"
            target="_blank"
            rel="noreferrer"
          >
            SNIPPET
          </a>
        </Spp>
        <Spp>
          Issuer account:{" "}
          <a
            href="https://stellar.expert/explorer/public/account/GCN2I52BQDAHGM2AYZGRJ3N4YQSDTUCHXLITKYFAFCG5DKSEVIJFK4FF"
            target="_blank"
            rel="noreferrer"
          >
            GCN2I52BQDAHGM2AYZGRJ3N4YQSDTUCHXLITKYFAFCG5DKSEVIJFK4FF
          </a>
        </Spp>
        <Spp>Max supply: Unlimited</Spp>
      </SPSection>
      <SPSection title="Tokenomics">
        <Spp>
          There's no max limit amount for SNIPPET. Our goal is to bring easy
          access to the token for every user and to keep them able to use the
          platform for a long time with a small amount.
        </Spp>
        <Spp>
          1 million SNIPPET was issued initially. 800.000 were put to sale
          through SNIPPET/DEVP sale offers and liquidity pools. 200.000 were
          reserved to give away randomly to accounts that we consider genuine
          and managed by humans, after manual review. The giveaway is still
          ongoing.
        </Spp>
        <Spp>
          Every hour passed, our servers check Horizon and issue 1 SNIPPET per
          each <em>ledger</em> processed by the Stellar blockchain. Usually
          ~600. These means ~600 SNIPPET are issued every hour and shared
          proportionally with users that already hold SNIPPET.
        </Spp>
        <Spp>
          With this aproach, users can spend a very little amount to get SNIPEPT
          and earn enough rewards to keep a moderate but constant usage of the
          platform that allow them to publish. Users with stronger publishing
          needs can spend more if they need it.
        </Spp>
        <Spp>
          Consuming public content created by other users (read access) won't
          spend any SNIPPET.
        </Spp>
      </SPSection>
      <SPSection title="Disclaimer">
        <Spp>
          SNIPPET is not an investment product. There are not any real world
          assets backing its value.
        </Spp>
        <Spp>
          Its specific functions are acting as a quota for the usage of the
          DevLand Network platform resources and serve as currency for paid
          content between users inside the platform.
        </Spp>
        <Spp>
          We won't allow a malicious usage of the token. If we need it we
          reserve the right to issue or burn the needed amount of token to keep
          the platform running without any obstacles, always after voting it
          through <strong>DEVP</strong>.
        </Spp>
      </SPSection>
    </Layout>
  )
}
export default AboutSNIPPETPage
